import React from 'react';
import { Typography } from '@mui/material';
import '../../App.css';
import { useNavigate } from "react-router-dom";
import BpmnView from './BpmnView';
import PaginaDialog from '../paginaDinamica/PaginaDialog';
import ReactDOM from 'react-dom/client';

export default function BpmnRoot(props) {
    const { fluxo } = props;
    const navigate = useNavigate();
    const style = fluxo.style?fluxo.style:{ width: '100%', height: fluxo.height };

    function onShown() {
        console.log('diagram shown');
    }

    function onLoading() {
        console.log('diagram loading');
    }

    function onError(err) {
        console.error('failed to show diagram', err);
    }

    const handleClick = (n) => {
        console.log("--->", n.id)
        if (fluxo.abrirPagina) {
            const item = fluxo.abrirPagina.find((p) => p.key === n.id);
            if (item) {
                navigate("/paginaDinamica/" + item.pagina);
                navigate(0);
            }
        }else if (fluxo.abrirDialog != null) {
            const item = fluxo.abrirDialog.find((p) => p.key === n.id);
            const root = ReactDOM.createRoot(
                document.getElementById('rederizarDialog')
              );

            if (item) {
                root.render(<PaginaDialog dialog={item} fluxos={fluxo.abrirDialog}/>);
            }
        }
    }
    return (
        <div style={style}>
            <Typography variant="caption" display="block"> {fluxo.nome}</Typography>
            <BpmnView
                url={fluxo.path}
                scaleX = {fluxo.calculeScaleX}
                scaleY = {fluxo.calculeScaleY}
                zoom = {fluxo.zoom}
                onShown={onShown}
                onLoading={onLoading}
                onError={onError}
                onClick={handleClick}
            />
        </div>
    );
}