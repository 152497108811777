import React, { useEffect, useState, useRef } from 'react';
import {
  Handle,
  Position,
//   useUpdateNodeInternals,
//   NodeResizer,
} from 'reactflow';
import styles from './style.module.css';

export default function RotateNode({
  id,
  sourcePosition = Position.Left,
  targetPosition = Position.Right,
  data,
}) {
  const rotateControlRef = useRef(null);
//   const updateNodeInternals = useUpdateNodeInternals();
  const [rotation, setRotation] = useState(data.rotation);
  const translateX = data.translateX;
  const translateY = data.translateY;
//   const [rotation, setRotation] = useState(-90);
useEffect(()=>{
    console.log( data.rotation, translateX, translateY );
}, [])

  return (
    <>
      <div 
        style={{
        //   transform: `rotate(${rotation}deg) translateX(${translateX})`,
        //   transform: `rotate(${rotation}deg) translateY(${translateY})`,
        }}
        className={styles.node}
      >
        <div  style={{       
          transform: `rotate(${rotation}deg) translate(${translateX}px, ${translateY}px)`,
        }}>
          {data?.label}
        </div>
        <Handle
          style={{ opacity: 0 }}
          position={sourcePosition}
          type="source"
        />
        <Handle
          style={{ opacity: 0 }}
          position={targetPosition}
          type="target"
        />
      </div>
    </>
  );
}