import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './App.css';
import Root from "./Root";
import ErrorPage from "./components/ErrorPage";
import FluxoRoot from "./components/fluxo/FluxoRoot";
import FluxoDinamicoRoot from "./components/fluxo/FluxoDinamicoRoot";
import PaginaRoot from "./components/paginaDinamica/PaginaRoot";
import BpmnRoot from "./components/bpmn/BpmnRoot";
import Home from "./components/Home";
import Noticias from "./components/noticias/Noticias";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    errorElement: <ErrorPage />,
    children: [      
      {
        path:"flow",
        element: <FluxoRoot />
      },      
      {
        path:"flowDinamico",
        element: <FluxoDinamicoRoot />
      },      
      {
        path:"paginaDinamica/:pagina",
        element: <PaginaRoot />
      },      
      {
        path:"bpmn/:pagina",
        element: <BpmnRoot />
      },      
      {
        path:"home",
        element: <Home />
      },      
      {
        path:"noticias",
        element: <Noticias />
      },      
      {
        path:"/",
        element: <Home />
      }
    ],
  }
]);



function App() {

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
