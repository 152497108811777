import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    margin: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 3,
  },
}));

export default function PaginaDialog(props) {
  const [open, setOpen] = React.useState(true);
  const { fluxos } = props;
  const [dialog, setDialog] = React.useState(props.dialog);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  const styleLink = {
    fontSize: '1em',
    color: '#143cb1'
  }


  useEffect(() => {
    /*console.log(dialog)*/
    const handleKey = (event) => {
      if (event.key === "ArrowRight" && dialog?.nextRight) {
        const item = fluxos.find((p) => p.key === dialog.nextRight);
        if (item) {
          setDialog(item);
        }
      } else if (event.key === "ArrowLeft" && dialog?.nextLeft) {
        const item = fluxos.find((p) => p.key === dialog.nextLeft);
        if (item) {
          setDialog(item);
        }
      }
    };

    if (dialog) {
      window.addEventListener("keydown", handleKey);
    }

    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, [dialog]);

  const opcao1 = () => {
    if (dialog !== null) {
      const item = fluxos.find((p) => p.key === dialog.opcao1);
      if (item) {
        setDialog(item);
      }
    }
  }

  const opcao2 = () => {
    if (dialog !== null) {
      const item = fluxos.find((p) => p.key === dialog.opcao2);
      if (item) {
        setDialog(item);
      }
    }
  }

  return (
    <div id={'paginaDialog' + dialog.key}>
      <BootstrapDialog style={{height: 'auto'}}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
        open={open}
      >
        {dialog.verificador === "Nao" && (
          <div>
            <DialogTitle sx={{ m: 0, p: 2, display: { xs: 'none', sm: 'block' } }} id="responsive-dialog-title-big">
              <div id='tituloDialogBig'>
                {dialog && dialog.titulo}
              </div>
            </DialogTitle>
            <DialogTitle sx={{ m: 0, p: 2, display: { xs: 'block', sm: 'none' } }} id="responsive-dialog-title-small">
              <div id='tituloDialogSmall'>
                {dialog && dialog.titulo}
              </div>
            </DialogTitle>
          </div>)
        }
        
        {dialog.verificador === "Sim" &&
          <DialogTitle style={{ cursor: 'move', marginRight: "2em" }} id="draggable-dialog-title">
            {dialog.titulo}
          </DialogTitle>
        }
        <IconButton
          style={{color: '#3972cb'}}
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {dialog.verificador === "Nao" &&
          <DialogContent dividers>
            <Box mb={2}>
              <Box sx={{
                display: 'flex',
                marginLeft: -4
              }} >
                <Box fontWeight="bold" width="20%">
                  Atividade:
                </Box>
                <Box width="80%" textAlign="justify">
                  {dialog && dialog.atividade}
                </Box>
              </Box>
            </Box>
            <Box mb={2}>
              <Box sx={{
                display: 'flex',
                marginLeft: -4
              }} >
                <Box fontWeight="bold" width="20%">
                  Descrição:
                </Box>
                <Box width="80%" textAlign="justify">
                  {dialog && dialog.descricao}
                </Box>
              </Box>
            </Box>
            <Box mb={2}>
              <Box sx={{
                display: 'flex',
                marginLeft: -4
              }} >
                <Box fontWeight="bold" width="20%">
                  Responsável:
                </Box>
                <Box width="80%" textAlign="justify">
                  {dialog && dialog.responsaval}
                </Box>
              </Box>
            </Box>
            <Box mb={2}>
              <Box sx={{
                display: 'flex',
                marginLeft: -4
              }} >
                <Box fontWeight="bold" width="20%">
                  Entradas:
                </Box>
                {!dialog.pathEntradas &&
                  <Box width="80%" textAlign="justify">
                    {dialog && dialog.entradas}
                  </Box>
                }
                {dialog.pathEntradas &&
                 <Box width="80%" textAlign="justify">
                  <a href={dialog.pathEntradas} style={styleLink}>
                      {dialog && dialog.entradas}
                  </a>
                  </Box>
                }
              </Box>
            </Box>
            <Box mb={2}>
              <Box sx={{
                display: 'flex',
                marginLeft: -4
              }} >
                <Box fontWeight="bold" width="20%">
                  Saídas:
                </Box>
                {!dialog.pathSaidas &&
                  <Box width="80%" textAlign="justify">
                    {dialog && dialog.saidas}
                  </Box>
                }
                {dialog.pathSaidas &&
                 <Box width="80%" textAlign="justify">
                  <a href={dialog.pathSaidas} style={styleLink}>
                      {dialog && dialog.saidas}
                  </a>
                  </Box>
                }

              </Box>
            </Box>
          </DialogContent>
        }
        {dialog.verificador === "Sim" &&
          <DialogContent dividers>
            <div style={{display: 'flex', flexDirection: 'row', position:'relative'}}>
              <div className='opcao1' onClick={opcao1} style={{marginRight: '1em'}}>
                {dialog.descricaoOpcao1}
              </div>
              <div className='opcao2' onClick={opcao2}>
                {dialog.descricaoOpcao2}
              </div>
            </div>
          </DialogContent>
        }
      </BootstrapDialog>
    </div>
  );
}
