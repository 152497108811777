import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <Box sx={{ bgcolor: 'black', color: 'white', p: 3, mt: 10, margin: 0, display: 'flex', justifyContent: 'center' }}>
      <Box
        component="img"
        src="/image.png"
        alt="Logo"
        sx={{
          width: {
            xs: 100,
            sm: 150,
          },
          maxWidth: '100%',
          margin: 'auto'
        }}
      />
    </Box>
  );
}
