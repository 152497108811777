const Service ={

    GET: function (url){
        return fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                //Authorization: `Bearer ${localStorage.accessToken}`
            }
          }).then( r => {
            if (r.ok) {
                return r.json();
            }else{
                if( r.status === 500){
                    throw "Servidor não responde apropriadamente.";
                }
                return r.json().then(erro =>{
                    console.error("Erro da requisicao: ",erro);
                    if( erro.status === 500){
                        if( erro.message.includes("signature does not match") ||
                            erro.message.includes("JWT expired") 
                          ){
                            throw "Problema ao verificar credenciais do usuário. Efetue novo login.";
                        }else{
                            throw (erro && erro.message)?erro.message:"";
                        }
                    }else{
                        throw erro;
                    }
                });
            }
        });
    }

}
export default Service;