import React from "react";
import { Handle, Position, useStore  } from 'reactflow';
import RotateNode from "./RotateNode";
// const connectionNodeIdSelector = (state) => state.connectionNodeId;

function CustomNode({ data }) {
  // const connectionNodeId = useStore(connectionNodeIdSelector);

  const isConnecting = false; //!!connectionNodeId;
  const isTarget = false; //connectionNodeId && connectionNodeId !== id;
  // const label = isTarget ? 'Drop here' : 'Drag to connect';

  return (
    <div className="customNode">
      <div
        className="customNodeBody"
        style={{
          borderStyle: isTarget ? 'dashed' : 'solid',
          backgroundColor: isTarget ? '#ffcce3' : '#ccd9f6',
        }}
      >
        {/* If handles are conditionally rendered and not present initially, you need to update the node internals https://reactflow.dev/docs/api/hooks/use-update-node-internals/ */}
        {/* In this case we don't need to use useUpdateNodeInternals, since !isConnecting is true at the beginning and all handles are rendered initially. */}
        {!isConnecting && (
          <Handle className="customHandle" position={Position.Right} type="source" />
        )}

        <Handle
          className="customHandle"
          position={Position.Left}
          type="target"
          isConnectableStart={false}
        />
        {data.label}
      </div>
    </div>
  );
}

const CircleNode = ({ data }) => {
    return (
      <div
        style={{
          backgroundColor: "#9ca8b3",
          padding: "14px",
          borderRadius: "50px"
        }}
      >
        <Handle
          type="target"
          position="left"
          id={`${data.id}.left`}
          style={{ borderRadius: "0" }}
        />
        <div id={data.id}>{data.label}</div>
        <Handle
          type="source"
          position="right"
          id={`${data.id}.right1`}
          style={{ top: "30%", borderRadius: 0 }}
        />
        <Handle
          type="source"
          position="right"
          id={`${data.id}.right2`}
          style={{ top: "70%", borderRadius: 0 }}
        />
      </div>
    );
  };
  const DiamondNode = ({ data }) => {
    return (
      <div className="diamond-node">
        {/* <Handle
          type="target"
          position="top"
          id={`${data.id}.top`}
          style={{ borderRadius: 0 }}
        /> */}
        <div id={data.id} className="triangle-node-text">
          {data.label}
        </div>
        <Handle type="target" position={Position.Top} />
        <Handle type="source" position={Position.Bottom} />

        {/* <Handle
          // className="customHandle"
          type="source"
          position={Position.Bottom}
          id={`${data.id}.bottom1`}
          // style={{ left: "0%", borderRadius: 0 }}
        /> */}
        {/* <Handle
          type="source"
          position={Position.Bottom}
          id={`${data.id}.bottom2`}
          // style={{ left: "70%", borderRadius: 10 }}
        /> */}
      </div>
    );
  };
const TriangleNode = ({ data }) => {
    return (
      <div className="triangle-node">
        {/* <Handle
          type="target"
          position="top"
          id={`${data.id}.top`}
          style={{ borderRadius: 0 }}
        /> */}
        <div id={data.id} className="triangle-node-text">
          {data.label}
        </div>
        <Handle type="target" position={Position.Top} />
        <Handle type="source" position={Position.Bottom} />

        {/* <Handle
          // className="customHandle"
          type="source"
          position={Position.Bottom}
          id={`${data.id}.bottom1`}
          // style={{ left: "0%", borderRadius: 0 }}
        /> */}
        {/* <Handle
          type="source"
          position={Position.Bottom}
          id={`${data.id}.bottom2`}
          // style={{ left: "70%", borderRadius: 10 }}
        /> */}
      </div>
    );
  };
  
  export const nodeTypes = {
    circle: CircleNode,
    // rectangle: RectangleNode,
    triangle: TriangleNode,
    custom: CustomNode,
    diamond: DiamondNode,
    rotate: RotateNode
    // text: TextNode
  };