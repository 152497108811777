import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Divider, Icon, Stack } from '@mui/material';

export default function PaginaMaterialApoio() {
    const style = {
      width: 'max-content',
      minWidth: '250px' ,
      left: '-0.4em',
      paddingLeft:'115px',
      paddingRight: '20px',
      textAlign: 'end'
    };

    const configWidthTitle = (sizeScreen) =>{
      if(document.getElementById('widthText')){
        if (sizeScreen.matches) { 
          document.getElementById('widthText').style.display = 'ruby';
          document.getElementById('textMaterialApoio').style.textAlign = 'left';
          document.getElementById('textMaterialApoio').style.paddingRight= '5vw';
          document.getElementById('textMaterialApoio').style.width = '250px';
        } else {
          document.getElementById('widthText').style.display = 'flex';
          document.getElementById('textMaterialApoio').style.textAlign = 'end';
          document.getElementById('textMaterialApoio').style.paddingRight= '20px';
          document.getElementById('textMaterialApoio').style.width = 'max-content';
        }
      }
    }

    const sizeScreen = window.matchMedia('(max-width: 464px)');
    sizeScreen.addEventListener("change", function() {
      configWidthTitle(sizeScreen);
    });

    const onShown = (sizeScreen) => {
      configWidthTitle(sizeScreen);
    }

  return (
    <Card sx={{ minWidth: 275, paddingTop: '6em'}} style={{ border: "none", boxShadow: "none" }} id="materialApoio">
      <CardContent>
        <Typography variant="h2" component="div" id="widthText"
        style={{
          display: 'flex'
        }}
        onShown={onShown(sizeScreen)}>
          <Box sx={{display: 'inline'}}>
          <div style={{display: 'flex', flexDirection: 'column-reverse', }} >
            <span className="descricaoTitulo" style={style} id='textMaterialApoio'>
              Material de apoio
            </span>
          </div>
          </Box>
        </Typography>
      </CardContent>
    </Card>
  );
}