import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Collapse, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, SpeedDial, SpeedDialAction, SpeedDialIcon, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import ApiIcon from '@mui/icons-material/Api';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export default function Header() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSubmenu, setOpenSubmenu] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const openMenu = Boolean(anchorEl);

    const handleSubmenuToggle = () => {
        setOpenSubmenu(!openSubmenu);
    };

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const redirecionarPagina = (linkPagina) => {
        window.location.href = linkPagina;
    }
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const actions = [
        { icon: <InstagramIcon />, name: 'Instagram', href: 'https://www.instagram.com' },
        { icon: <FacebookIcon />, name: 'Facebook', href: 'https://www.facebook.com' },
        { icon: <LinkedInIcon />, name: 'LinkedIn', href: 'https://www.linkedin.com' },
        { icon: <YouTubeIcon />, name: 'YouTube', href: 'https://www.youtube.com' },
        { icon: <XIcon />, name: 'Twitter', href: 'https://www.twitter.com' },
    ];

    const DrawerList = (
        <Box sx={{ textAlign: 'center' }} role="presentation" onClick={toggleDrawer(true)}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Portal - Very
            </Typography>
            <Divider />
            <List>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="/home">
                        <ListItemIcon>
                            <AppShortcutIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Início"} />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={handleSubmenuToggle}>
                        <ListItemIcon>
                            <ApiIcon />
                        </ListItemIcon>
                        <ListItemText primary="Metodologia" />
                        {openSubmenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} component="a" href="/paginaDinamica/001desenvolvimentoDeSoftware">
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Desenvolvimento de Software" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} component="a" href="/paginaDinamica/001manutencaoSoftware">
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Manutenção de Software" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} component="a" href="/paginaDinamica/paginaSuporte">
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Suporte de Software" />
                        </ListItemButton>
                    </List>
                </Collapse>

                <ListItem disablePadding>
                    <ListItemButton component="a" href="/paginaDinamica/pagina02">
                        <ListItemIcon>
                            <ApiIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Notícias"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="/paginaDinamica/pagina03">
                        <ListItemIcon>
                            <ApiIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Sistemas"} />
                    </ListItemButton>
                </ListItem>
            </List>

        </Box>
    );

    return (
        <Box sx={{ margin: 0, padding: 0}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px', alignItems: 'center', maxWidth: '100%' }}>
                <Box
                    component="img"
                    src="/logoSP.jpeg"
                    alt="Logo"
                    sx={{
                        width: {
                            xs: 100,
                            sm: 150,
                            md: 200,
                        },
                        marginLeft: {
                            xs: 1,
                            sm: 2,
                            md: 4,
                        },
                        maxWidth: '100%',
                    }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginRight: { xs: 1, sm: 2, md: 4 } }}>
                        {actions.map((action) => (
                            <IconButton
                                key={action.name}
                                aria-label={action.name}
                                color="default"
                                href={action.href}
                                target="_blank"
                                rel="noopener"
                            >
                                {action.icon}
                            </IconButton>
                        ))}
                        <Typography sx={{ color: 'black', fontSize: 12 }}>
                            /governosp
                        </Typography>
                    </Box>

                </Box>
            </Box>
            <Box sx={{ backgroundColor: 'black', margin: 0 }}>

                <Box>
                    <IconButton
                        size="small"
                        onClick={handleDrawerOpen}
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ display: { sm: 'none' }, color: 'white' }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center', width: '100%', margin: 0 }}>
                    <Button sx={{ color: '#fff' }} href='/home'>
                        Início
                    </Button>
                    <div className='button-menu'>
                        <Button
                            id="button-metodologia"
                            aria-controls={openMenu ? 'submenu-metodologia' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            Metodologia
                        </Button>
                        <Menu
                            id="submenu-metodologia"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'button-metodologia',
                            }}
                        >
                            <MenuItem onClick={() => redirecionarPagina("/paginaDinamica/001desenvolvimentoDeSoftware")}>
                                Desenvolvimento de Software
                            </MenuItem>
                            <MenuItem onClick={() => redirecionarPagina("/paginaDinamica/001manutencaoSoftware")}>
                                Manutenção de Software
                            </MenuItem>
                            <MenuItem onClick={() => redirecionarPagina("/paginaDinamica/paginaSuporte")}>
                                Suporte de Software
                            </MenuItem>
                        </Menu>
                    </div>
                    <Button sx={{ color: '#fff' }} href='/noticias'>
                        Notícias
                    </Button>
                    <Button sx={{ color: '#fff' }} href='/paginaDinamica/pagina03'>
                        Sistemas
                    </Button>
                </Box>

                <Drawer open={open} onClose={toggleDrawer(false)}>
                    {DrawerList}
                </Drawer>
            </Box>
        </Box>
    );
}
