import * as React from 'react';
import Box from '@mui/material/Box';
import Header from './components/header/Header'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Outlet } from "react-router-dom";
import Footer from './components/footer/Footer';

export default function Root() {
    return (
        <>
            <Header />
            <Box sx={{ padding: 0, margin: 0, marginTop: 15, marginBottom: 10 }}>
                <Outlet />
            </Box>
            <Footer />
        </>
    );
}