import React, { useState, useEffect } from 'react';
import { CardMedia, Grid, Typography, Container, Box, Divider, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Service from '../service/Service';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

export default function Noticias(props) {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        chamarNoticia();
    }, []);

    const chamarNoticia = async () => {
        try {
            const response = await Service.GET('https://servicodados.ibge.gov.br/api/v3/noticias/');
            if (Array.isArray(response.items)) {
                setNews(response.items);
            } else {http://agenciadenoticias.ibge.gov.br/agencia-noticias/2012-agencia-de-noticias/noticias/40427-oficina-tecnica-sobre-censo-2022-reune-gestores-publicos-e-privados-em-fortaleza.html
                setNews([]);
            }
            setLoading(false);
        } catch (erro) {
            setError(erro);
            setLoading(false);
        }
    };

    const handlerImagem = (apiData) => {
        const imagens = JSON.parse(apiData);
        return 'https://agenciadenoticias.ibge.gov.br/' + imagens.image_intro;
    };


    if (error) {
        return (
            <Container sx={{ marginTop: '20px', marginBottom: '20px' }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        <Box
                            sx={{

                                backgroundColor: '#f5f5f5',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                p: 2,
                                borderRadius: 2
                            }}
                        >
                            <ReportProblemIcon color='error' sx={{ fontSize: 60 }} />
                            <Typography>
                                Error: {error.message}

                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    if (loading) {
        return (
            <Container sx={{ marginTop: '20px', marginBottom: '20px' }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        <Box
                            sx={{
                                width: '600px',
                                height: '530px',
                                backgroundColor: '#f5f5f5',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                p: 2,
                                borderRadius: 2
                            }}
                        >
                            <Skeleton variant="rectangular" width="100%" height="232px" sx={{ borderRadius: 2 }} />
                            <Skeleton width="80%" sx={{ mt: 2 }} />
                            <Skeleton width="60%" />
                        </Box>
                    </Grid>

                    <Grid item>
                        <Box
                            sx={{
                                width: '450px',
                                height: '245px',
                                backgroundColor: '#f5f5f5',
                                marginBottom: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                alignItems: 'flex-start',
                                p: 2,
                                borderRadius: 2
                            }}
                        >
                            <Skeleton variant="rectangular" width="100%" height="122px" sx={{ borderRadius: 2 }} />
                            <Skeleton width="80%" sx={{ mt: 2 }} />
                            <Skeleton width="60%" />
                        </Box>
                        <Box
                            sx={{
                                width: '450px',
                                height: '245px',
                                backgroundColor: '#f5f5f5',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                p: 2,
                                borderRadius: 2
                            }}
                        >
                            <Skeleton variant="rectangular" width="100%" height="122px" sx={{ borderRadius: 2 }} />
                            <Skeleton width="80%" sx={{ mt: 2 }} />
                            <Skeleton width="60%" />
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ marginTop: 7, marginBottom: 7 }} />
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                    <Box sx={{ maxWidth: '100%', width: '100%' }}>
                        <Slider {...settings}>
                            {Array.from(new Array(3)).map((_, index) => (
                                <Box
                                    key={index}
                                    sx={itemStyle}
                                >
                                    <Skeleton variant="rectangular" width="85%" height="122px" sx={{ borderRadius: 2 }} />
                                    <Skeleton width="80%" sx={{ mt: 2 }} />
                                    <Skeleton width="60%" />
                                </Box>
                            ))}
                        </Slider>
                    </Box>
                </Grid>
            </Container>
        )
    }

    return (
        <Container sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <Box
                        sx={{
                            width: '500px',
                            height: '590px',
                            backgroundColor: '#f5f5f5',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            p: 2,
                            borderRadius: 2
                        }}
                    >
                        <Link target='_blank' to={news.length > 0 ? news[0]?.link : '/home'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <CardMedia
                                component="img"
                                image={news.length > 0 ? handlerImagem(news[0].imagens) : ''}
                                alt="Imagem da notícia"
                                sx={{ height: '232px', width: '100%', objectFit: 'cover', borderRadius: 2 }}
                            />
                            <Typography sx={{ mt: 2 }}>
                                {news.length > 0 ? news[0].introducao : ''}
                            </Typography>
                        </Link>
                    </Box>
                </Grid>

                <Grid item>
                    <Box
                        sx={{
                            width: '470px',
                            height: '290px',
                            backgroundColor: '#f5f5f5',
                            marginBottom: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            p: 2,
                            borderRadius: 2
                        }}
                    >
                        <Link target='_blank' to={news[1].link} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <CardMedia
                                component="img"
                                image={news.length > 0 ? handlerImagem(news[1].imagens) : ''}
                                alt="Imagem da notícia"
                                sx={{ height: '122px', width: '100%', objectFit: 'cover', borderRadius: 2 }}
                            />
                            <Typography sx={{ mt: 2 }}>
                                {news.length > 0 ? news[1].introducao : ''}
                            </Typography>
                        </Link >
                    </Box>
                    <Box
                        sx={{
                            width: '470px',
                            height: '290px',
                            backgroundColor: '#f5f5f5',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            p: 2,
                            borderRadius: 2
                        }}
                    >
                        <Link target='_blank' to={news[2].link} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <CardMedia
                                component="img"
                                image={news.length > 2 ? handlerImagem(news[2].imagens) : ''}
                                alt="Imagem da notícia"
                                sx={{ height: '122px', width: '100%', objectFit: 'cover', borderRadius: 2 }}
                            />
                            <Typography sx={{ mt: 2 }}>
                                {news.length > 2 ? news[2].introducao : ''}
                            </Typography>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ marginTop: 7, marginBottom: 7 }} />

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                <Box sx={{ maxWidth: '100%', width: '100%' }}>
                    <Slider {...settings}>
                        {news.slice(3).map((item, index) => (


                            <Box
                                key={index}
                                sx={itemStyle}

                            >
                                <Link target='_blank' to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <CardMedia
                                        component="img"
                                        image={handlerImagem(item.imagens)}
                                        alt={`Imagem da notícia ${index}`}
                                        sx={{ height: '122px', width: '85%', objectFit: 'cover', borderRadius: 2 }}
                                    />
                                    <Typography sx={{ mt: 2, width: '85%', height: '122px' }}>
                                        {item.introducao}
                                    </Typography>
                                </Link>
                            </Box>
                        ))}
                    </Slider>
                </Box>
            </Grid>
            "Mateus cardoso lindo e gostoso"
        </Container>
         
    );

};

const itemStyle = {
    width: '410px',
    height: '350px',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    p: 2,
    borderRadius: 2,
    margin: '0 1px',
};

    
