import React from 'react';
import ReactFlow, { Controls, MarkerType } from 'reactflow';
import parse from 'html-react-parser';
import 'reactflow/dist/style.css';
import FluxoCard from './FluxoCard';
import CustomNode from './CustomNode';
// import FloatingEdge from './FloatingEdge';
// import CustomConnectionLine from './CustomConnectionLine';
import { Typography } from '@mui/material';
import { nodeTypes } from "./Nodes";
import '../../App.css';
import Service from '../../services/Service';
import { useNavigate } from "react-router-dom";

// const edgeTypes = {
//     floating: FloatingEdge,
// };
const defaultEdgeOptions = {
    style: { strokeWidth: 2, stroke: 'black' },
    type: 'floating',
    markerEnd: {
        type: MarkerType.ArrowClosed,
        color: 'black',
    },
};

export default function FluxoDinamicoRoot(props) {
    const { fluxo } = props;
    const navigate = useNavigate();
    const [style, setStyle] = React.useState({ width: '100%', height: '40vh' });
    const [nodes, setNodes] = React.useState();
    const [edges, setEdges] = React.useState();
    React.useEffect(() => {
        if (!fluxo) return;
        console.log( '>>>fluxo:', fluxo)
        const busca = () => {
            Service.GET("../"+fluxo)
                .then(r => {
                    let j = [...r.nodes]
                    for (let x in j) {
                        let item = j[x];

                        if (item.type === "react") {
                            delete item['type'];
                            if (item.data.label && item.data.label.startsWith('<FluxoCard')) {
                                const atrib = getAtributos(item.data.label);                               
                                let la = atrib[0].trim(); //'lalalala';
                                let el = null;
                                if (atrib.length > 1) {
                                    el = React.createElement(FluxoCard, { nome: la, descricao: atrib[1] });
                                } else {
                                    el = React.createElement(FluxoCard, { nome: la });
                                }
                                item.data.label = el;
                            }
                        } else if (item.data.label && item.data.label.startsWith('<')) {
                            console.log(item.data.label);
                            item.data.label = <div dangerouslySetInnerHTML={{ __html: item.data.label }} />
                            // item.data.label = <div dangerouslySetInnerHTML={{__html:"<div style='height:100px;'><FluxoCard/></div>" }} />
                            //item.data.label = <FluxoCard/>
                            // const DynamicComponent = lazy(() => import("./FluxoCard"));
                            // var MyComponent = Components[item.data.label];
                            // item.data.label = DynamicComponent;
                            // item.data.label = parse(item.data.label)
                            //item.data.label = parse('<div style=""><FluxoCard/></div>');
                        }
                    }
                    setNodes(j);
                    setEdges(r.edges);
                    if( r.style) setStyle(r.style);

                });
        }
        busca();
    }, [fluxo])
    const onNodeClick = (event, node) => {
        console.log('click node', node);
        if( node.pagina && node.pagina){
            // if( node.pagina.endsWith(".json")){
                const txt = node.pagina.replace(".json", "")
                navigate("/paginaDinamica/"+node.pagina);
                navigate(0);
            // }
        }

    }

    function getAtributos(tag) {
        let txt = tag.replace('<FluxoCard', '').trim();
        txt = txt.replace('/>', '');
        txt = txt.replaceAll("'", '');
        txt = txt.replace('"', '');

        const ar = txt.split("=");
        let arr = [];
        for (let i = 0; i < ar.length; i++) {
            txt = ar[i + 1];
            arr.push(txt && txt.replace('descricao', ''));
            // i++;            
        }
        return arr;
    }
    function montFluxoNome(){
        let txt = fluxo.replace(".json", "");
        const arr = txt.split("/");
        return "Fluxo: "+arr[ arr.length-1];
    }
    return (
        <div style={style}>
            <Typography variant="caption" display="block" gutterBottom>{montFluxoNome()}</Typography>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                fitView
                nodeTypes={nodeTypes}
                // edgeTypes={edgeTypes}
                // defaultEdgeOptions={defaultEdgeOptions}
                onNodeClick={onNodeClick}
            >
                <Controls />
            </ReactFlow>
        </div>
    );
}