import React, { useState, useEffect } from 'react';
import { CardMedia, Grid, Typography, Container, Box, Divider, Skeleton, Paper, CardActionArea, CardContent, Card, List, ListItem, ListItemText } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Service from './service/Service';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

export default function Home(props) {
    const navigate = useNavigate();
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        chamarNoticia();
    }, []);

    const chamarNoticia = async () => {
        try {
            const response = await Service.GET('https://servicodados.ibge.gov.br/api/v3/noticias/');
            if (Array.isArray(response.items)) {
                setNews(response.items);
                console.log(response.items)
            } else {
                http://agenciadenoticias.ibge.gov.br/agencia-noticias/2012-agencia-de-noticias/noticias/40427-oficina-tecnica-sobre-censo-2022-reune-gestores-publicos-e-privados-em-fortaleza.html
                setNews([]);
            }
            setLoading(false);
        } catch (erro) {
            setError(erro);
            setLoading(false);
        }
    };

    const handlerImagem = (apiData) => {
        const imagens = JSON.parse(apiData);
        return 'https://agenciadenoticias.ibge.gov.br/' + imagens.image_intro;
    };

    const programas = [
        { titulo: 'Renda Cidadã', img: 'image1.png', subTitulo: 'O Renda Cidadã oferece transferência de renda e ações complementares para famílias vulneráveis, exigindo frequência escolar e acompanhamento de saúde. Interessados devem procurar o CRAS para cadastro.' },
        { titulo: 'Bolsa do Povo', img: 'image1.png', subTitulo: 'O programa Bom Prato, criado em 2000, oferece refeições saudáveis a preços acessíveis (almoço e jantar a R$ 1,00, café da manhã a R$ 0,50) e serve 138 mil refeições diárias em 120 unidades no estado de São Paulo.' },
        { titulo: 'Espaço Prevenir', img: 'image1.png', subTitulo: 'O Ação Jovem promove a inclusão social de jovens de 15 a 24 anos com renda familiar de até meio salário mínimo, oferecendo R$ 100,00 mensais. O programa está presente em 642 municípios e requer ...' },

    ];
    return (
        <Grid sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            gap: '10px',
            boxSizing: 'border-box'
        }}>

            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },  // Adiciona responsividade
                gap: '35px'
            }}>
                {news.slice(0, 1).map((card, index) => (
                    <Grid key={index} item sx={{
                        backgroundColor: '#e8e6e6',
                        width: { xs: '80%', md: '800px' },  // Ajusta a largura para telas menores
                        height: '400px',
                        padding: '10px',
                        boxSizing: 'border-box',
                        borderRadius: 7
                    }}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#e8e6e6', boxShadow: 'none' }}>
                            <CardMedia
                                component="img"
                                height="250"
                                image={handlerImagem(card.imagens)}
                                alt="Minha Imagem"
                                sx={{ borderRadius: 7, objectFit: 'cover' }}
                            />
                            <CardContent sx={{ padding: '10px 0 0 0' }}>
                                <Typography variant="body2" color="textSecondary" component="p" sx={{ padding: '10px', boxSizing: 'border-box' }}>
                                    {card.introducao}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                <Grid item sx={{
                    backgroundColor: '#e8e6e6',
                    width: { xs: '80%', md: '400px' },  // Ajusta a largura para telas menores
                    height: '400px',
                    padding: '10px',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    borderRadius: 7
                }}>
                    <Typography variant="h6" sx={{ margin: '5px 0' }}>
                        Mais Lidas
                    </Typography>
                    <List sx={{ width: '100%', padding: '0 10px' }}>
                        {news.slice(1, 10).map((card, index) => (
                            <React.Fragment key={index}>
                                <ListItem sx={{ padding: '2px 0' }}>
                                    <ListItemText
                                        primary={card.titulo}
                                        primaryTypographyProps={{
                                            noWrap: true,
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden'
                                        }}
                                    />
                                </ListItem>
                                {index < news.slice(1, 10).length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                </Grid>
            </Box>

            <Divider sx={{
                marginTop: '100px',
                width: '95%',
            }} />

            <Grid item sx={{
                backgroundColor: '#e8e6e6',
                width: '100%',
                height: 'auto',
                marginTop: '100px',
                padding: '10px',
                boxSizing: 'border-box',
                textAlign: 'center',
                marginBottom: '100px'
            }}>
                <Typography variant="h4" sx={{ marginBottom: '20px' }}>
                    Nossos Programas
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },  // Adiciona responsividade
                    justifyContent: 'center',
                    gap: '75px'
                }}>
                    {programas.map((programa, index) => (
                        <Card key={index} sx={{
                            width: '300px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: 7
                        }}>
                            <CardMedia
                                component="img"
                                sx={{
                                    borderRadius: '50%',
                                    width: '140px',
                                    height: '140px',
                                    objectFit: 'cover',
                                    margin: '10px 0',
                                    backgroundColor: '#e8e6e6'
                                }}
                                image={programa.img}
                                alt={programa.titulo}
                            />
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                                    {programa.titulo}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {programa.subTitulo}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Grid>
        </Grid>


    );

}; 
