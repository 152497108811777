import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Icon } from '@mui/material';
import FeedIcon from '@mui/icons-material/Feed';

export default function PaginaMaterialApoioContent(props) {
    const {fluxos} = props;

    const styleMaterialApoio = {
      width: '4em', 
      height: '4em',
      padding: '1.5em',
      paddingBottom: '0em',
      display: "inline-table",
      borderRadius: '1em'
    };

    const styleDivMaterais = {
      display:'inline-grid', background: 'white', 
      marginLeft: '1em', 
      marginRight: '1em', 
      borderRadius:'1em',  
      marginBottom: '1em', 
      height: '12rem', 
      width: '11rem',
      padding: '0.5em',
      textDecoration: 'none'
    }
    
    const styleIcon = {
      width: '4em', 
      height: '4em',
      color: '#2a71df',
      justifyContent: 'center',alignItems: 'center', textAlign: 'center'
    };

    const sizeScreen = [
      window.matchMedia("(min-width: 433px) and (max-width: 816px)"),
      window.matchMedia("(max-width: 432px)")
    ]

    for (var i=0; i<sizeScreen.length; i++){
      sizeScreen[i].addEventListener("change", function() {
        configWidthTitle(sizeScreen);
      });
    }

    const onShown = (sizeScreen) => {
      configWidthTitle(sizeScreen);
    }

    const configWidthTitle = (sizeScreen) =>{
      if(document.getElementById('conteudoMaterial')){
        if (sizeScreen[0].matches) { 
          document.getElementById('conteudoMaterial').style.minHeight = '28rem';
        } else if (sizeScreen[1].matches) { 
          document.getElementById('conteudoMaterial').style.minHeight = '52rem';
        } else {
          document.getElementById('conteudoMaterial').style.minHeight = '16rem';
        }
      }
    }

  return (
    <Card sx={{ minWidth: 275}} style={{ border: "none", boxShadow: "none", minHeight: '16rem' }} 
    id='conteudoMaterial' onShown={onShown(sizeScreen)}>
    <CardContent style={{background: '#c9d2db', left: '0em',
    position: 'absolute', right:'0em'}}>
        <Typography  component="div" >
          <Box sx={{display: 'inline'}}>
          <div  style={{justifyContent: 'center',alignItems: 'center', textAlign: 'center'}}>
          {fluxos && fluxos.map((f) => (
                f.materialApoio && f.materialApoio.map((material, i) => (
                  <a href={material.path} style={styleDivMaterais} key={i}>
                    <Icon style={styleMaterialApoio}>
                      <FeedIcon style={styleIcon} />
                    </Icon>
                    <span style={{ paddingBottom: '1em', color:'black', fontSize: '1.3em' }}>{material.titulo}</span>
                  </a>
                ))
            ))}
          </div>
          </Box>
        </Typography>
      </CardContent>
    </Card>
  );
}