import React from 'react';
import ReactFlow, { Controls, MarkerType }  from 'reactflow';
 
import 'reactflow/dist/style.css';
import FluxoCard from './FluxoCard';
import CustomNode from './CustomNode';
// import FloatingEdge from './FloatingEdge';
// import CustomConnectionLine from './CustomConnectionLine';
import { nodeTypes } from "./Nodes";
import '../../App.css';

// const nodeTypes = {
//     custom: CustomNode,
// };
// const edgeTypes = {
//     floating: FloatingEdge,
// };
  const defaultEdgeOptions = {
    style: { strokeWidth: 2, stroke: 'black' },
    type: 'floating',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: 'black',
    },
  };

const initialNodes = [
  { id: '1', position: { x: 0, y: 0 }, data: { label: '1' }, type: 'input', },
  { id: '2', position: { x: 0, y: 100 }, data: { label: '222' }, type: 'custom', },
  {
    id: '3',
    // you can also pass a React component as a label
    data: { label: <FluxoCard/> },
    position: { x: 200, y: 5 },
  },
  {
    id: '4',
    data: { label: 'World' },
    position: { x: 0, y: 300 },
  },
  {
    id: '5',
    data: { label: 'bla' },
    position: { x: 200, y: 400 },
  },
  {
    id: 'A',
    type: 'group',
    data: { label: null },
    position: { x: 500, y: 0 },
    style: {
      width: 170,
      height: 140,
    },
  },
  {
    id: 'B',
    // type: 'input',
    data: { label: 'child node 1' },
    position: { x: 10, y: 10 },
    parentId: 'A',
    extent: 'parent',
  },
  {
    id: 'C',
    // type: 'output',
    data: { label: 'child node 2' },
    position: { x: 10, y: 90 },
    parentId: 'A',
    extent: 'parent',
  },
  {
    id: '6',
    type: "triangle",
    data: { label: 'Triangulo' },
    position: { x: 500, y: 300 },
  },
];
const initialEdges = [
    { id: 'e1-2', source: '1', target: '2' },
    { id: 'e2-3', source: '2', target: '3', animated: true },
    { id: 'e3-4', source: '3', target: '4', label: 'to the', type: 'step' },
    { id: 'e3-5', source: '3', target: '5', label: 'outro', type: 'step' },
    { id: 'e3-A', source: '3', target: 'B', label: 'outro', type: 'step' },
    { id: 'eB-C', source: 'B', target: 'C', label: 'outro', type: 'step' },
    { id: 'eC-5', source: 'C', target: '6', label: 'outro', type: 'step' },
    { id: 'e6-5', source: '6', target: '5', label: 'fim', type: 'step' },
];
 
export default function FluxoRoot() {

    const [nodes, setNodes] = React.useState(initialNodes);
    const [edges, setEdges] = React.useState(initialEdges);
  
  return (
    <div style={{ width: '100%', height: '80vh' }}>
      {/* <ReactFlow nodes={initialNodes} edges={initialEdges} /> */}
      <ReactFlow 
        nodes={nodes} 
        edges={edges} 
        fitView 
        nodeTypes={nodeTypes}
        // edgeTypes={edgeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        >
        <Controls />
      </ReactFlow>
    </div>
  );
}