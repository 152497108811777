import React from "react";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import BpmnJS from 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js';

export default class BpmnView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.containerRef = React.createRef();
    this.isAdjustingViewbox = false;
  }

  componentDidMount() {
    const {
      url,
      diagramXML
    } = this.props;

    const container = this.containerRef.current;

    this.bpmnViewer = new BpmnJS({
      container
    });

    this.bpmnViewer.on('import.done', (event) => {
      const {
        error,
        warnings
      } = event;

      if (error) {
        return this.handleError(error);
      }

      this.bpmnViewer.get('canvas').zoom('fit-viewport');

      this.limitZoom();

      this.limitMoviemtacao();

      return this.handleShown(warnings);
    });

    this.bpmnViewer.on('element.click', (event) => {
      const { onClick } = this.props;
      if (onClick) onClick(event.element);
    });

    if (url) {
      return this.fetchDiagram(url);
    }

    if (diagramXML) {
      return this.displayDiagram(diagramXML);
    }
  }

  componentWillUnmount() {
    this.bpmnViewer.destroy();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      props,
      state
    } = this;

    if (props.url !== prevProps.url) {
      return this.fetchDiagram(props.url);
    }

    const currentXML = props.diagramXML || state.diagramXML;

    const previousXML = prevProps.diagramXML || prevState.diagramXML;

    if (currentXML && currentXML !== previousXML) {
      return this.displayDiagram(currentXML);
    }
  }

  displayDiagram(diagramXML) {
    this.bpmnViewer.importXML(diagramXML);
  }

  fetchDiagram(url) {
    this.handleLoading();

    fetch(url)
      .then(response => response.text())
      .then(text => this.setState({ diagramXML: text }))
      .catch(err => this.handleError(err));
  }

  handleLoading() {
    const { onLoading } = this.props;

    if (onLoading) {
      onLoading();
    }
  }

  handleError(err) {
    const { onError } = this.props;

    if (onError) {
      onError(err);
    }
  }

  handleShown(warnings) {
    const { onShown } = this.props;

    if (onShown) {
      onShown(warnings);
    }
  }

  limitMoviemtacao() {
    const canvas = this.bpmnViewer.get('canvas');
    const eventBus = this.bpmnViewer.get('eventBus');

    const {
      props,
    } = this;

    // Limitar a movimentação do canvas aos limites do diagrama
    eventBus.on('canvas.viewbox.changed', (event) => {
      if (this.isAdjustingViewbox) {
        return;
      }

      this.isAdjustingViewbox = true;

      const viewbox = canvas.viewbox();
      const { outer, scale, inner } = viewbox;

      const minX = 0;
      const maxX = Math.max (0 , (inner.width + inner.width *  props.scaleX)-  outer.width / scale) ; // - (inner.width*0.8) ;
      const minY = 0;
      const maxY = Math.max (0 , (inner.height + inner.height * props.scaleY)- outer.height  / scale) ; //  - (inner.height*.8) ;

      if (minX > viewbox.x) {
        viewbox.x = minX
      }
      if (minY > viewbox.y) {
        viewbox.y = minY
      }
      if (maxX < viewbox.x) {
        viewbox.x = maxX
      }
      if (maxY < viewbox.y) {
        viewbox.y = maxY
      }

      canvas.viewbox(viewbox);
      this.isAdjustingViewbox = false;
    });
  }

  limitZoom() {
    const {
      props,
    } = this;

    const canvas = this.bpmnViewer.get('canvas');
    const minZoom = props.zoom;
    canvas.zoom('fit-viewport');

    this.bpmnViewer.get('eventBus').on('canvas.viewbox.changed', function (event) {
      const zoom = canvas.zoom();
      if (zoom < minZoom) {
        canvas.zoom(minZoom);
      }
    });
  }

  render() {
    return (
      <div style={{ width: '100%', height: '95%' }} ref={this.containerRef}></div>
    );
  }
}
