import FluxoImagem from '../../images/fluxo_executar_sprint.png'

export default function Glosario () {
    
  return (
    <div className='glosario' >
            <div style={{margin:'0px', fontWeight: 'bold'}}>
              <h4 className='glosarioText'>Glossário</h4>
            </div>

            <div style={{marginBottom:'3px'}}>
              <div className='elementGlosario'>
                <div className='circleInicio'></div>
                <div className='tituloForma'>Início</div>
              </div>

              <div className='elementGlosario'>
                <img className='quadrado' src={FluxoImagem} alt="Cidade"></img>
                <div className='tituloForma'>Subprocessos</div>
              </div>

              <div className='elementGlosario'>
                <div className='losango'></div>
                <div className='tituloFormaDecisao'>Decisão</div>
              </div>

              <div className='elementGlosario'>
                <div className='circleFim'></div>
                <div className='tituloForma'>Fim</div>
              </div>
            </div>
          </div>
  )
}