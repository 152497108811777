import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export default function PaginaDescricao(props) {
    const { pagina } = props;
    const [conteudo, setConteudo] = React.useState();

    React.useEffect(()=>{
        if(!pagina) return;
        if( pagina.startsWith("<")){
            return (
                setConteudo(pagina)
            )
        }else{
            fetch('../html/'+pagina)
                .then(function(response) {
                    // When the page is loaded convert it to text
                    return response.text()
                })
                .then(function(html) {                    
                    setConteudo(html);
                })
                .catch(function(err) {  
                    console.log('Failed to fetch page: ', err);  
                });
        }
    }, [pagina]);

    return (
        <Box 
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    // m: 1,
                    width: '100%',
                    // height: 128,
                },
                margin: 0
            }}
        >
            <Paper elevation={0}>
                {/* <MontaConteudo /> */}
                {conteudo && <div id="conteudo"
                    dangerouslySetInnerHTML={{__html: conteudo}}/> 
            }
            </Paper>
        </Box>
    );
}