import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Divider, Stack } from '@mui/material';
import { Padding } from '@mui/icons-material';

export default function PaginaHeader(props) {
    const {pagina} = props;
    const style = {
      width: "max-content",
      minWidth: pagina && pagina.configTitle.minWidth,
      left: '-0.4em',
      paddingLeft:'115px',
      paddingRight: '20px',
      textAlign: 'end'
    };

    const widthTitle = {config: pagina && pagina.configTitle.widthModeTitle}
    const configWidthTitle = (sizeScreen) =>{
      if(document.getElementById('widthTitle')){
        if (sizeScreen.matches) { 
          document.getElementById('widthTitle').style.display = 'ruby';
          document.getElementById('descricaoTitulo').style.width = 'min-content';
          document.getElementById('descricaoTitulo').style.textAlign = 'left';
          document.getElementById('descricaoTitulo').style.paddingRight= '20px';
        } else {
          document.getElementById('widthTitle').style.display = 'flex';
          document.getElementById('descricaoTitulo').style.width = 'max-content';
          document.getElementById('descricaoTitulo').style.textAlign = 'end';
          document.getElementById('descricaoTitulo').style.paddingRight= '20px';
        }
      }
    }
    const sizeScreen = window.matchMedia('(max-width: '+ widthTitle.config +')');
    sizeScreen.addEventListener("change", function() {
      configWidthTitle(sizeScreen);
    });

    const onShown = (sizeScreen) => {
      configWidthTitle(sizeScreen);
    }

    function montaLink(link){
        if( link.endsWith(".json")){
            const txt = link.replace(".json", "")
            return "/paginaDinamica/"+txt;
        }
        return "/paginaDinamica/"+link
    }
    const MontaHieraria = ()=>{
        if( pagina && pagina.hierarquia){
            return (
                <Stack direction="row" spacing={1}>
                    {pagina && pagina.hierarquia && pagina.hierarquia.map((h)=>(                        
                        <a href={montaLink(h.id)} key={h.id}>-> {h.nome}</a>
                    ))} 
                </Stack>
            );
        }
        return (<div/>)
    }
  return (
    <Card sx={{ minWidth: 275 }} style={{ border: "none", boxShadow: "none" }}>
      <CardContent>
        {<MontaHieraria/>}    
        <Typography id="widthTitle" variant="h2" component="div"
          style={{
            display: 'flex'
          }}
          onShown={onShown(sizeScreen)}>
          <Box sx={{display: { xs: 'block', sm: 'block' }}}>
          <div style={{display: 'flex', flexDirection: 'column-reverse', }} >
            <span className="descricaoTitulo" style={style} id='descricaoTitulo'>
              {pagina && pagina.nome}
            </span>
          </div>
          </Box>
        </Typography>
      </CardContent>
    </Card>
  );
}