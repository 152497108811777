import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import PaginaHeader from './PaginaHeader';
import PaginaDescricao from './PaginaDescricao';
import BpmnRoot from '../bpmn/BpmnRoot';
import Service from '../../services/Service';
import { useParams, useLocation } from 'react-router-dom';
import Glosario from './Glosario';
import PaginaMaterialApoio from './PaginaMaterialApoio';
import PaginaMaterialApoioContent from './PaginaMaterialApoioContent';

export default function PaginaRoot() {
  const [pagina, setPagina] = React.useState();
  const params = useParams();

  const location = useLocation()
  React.useEffect(() => {
    const busca = () => {
      Service.GET('/paginas/' + params.pagina + '.json')
        .then(r => {
          /*console.log("aquii",r)*/
          setPagina(r)
        });
    }
    busca();
  }, [location.key])

  return (
    <div style={{ width: '90%', height: '10%', margin: 100}}>
      <Grid container spacing={0}>
        <Grid xs={12} id="header">
          <PaginaHeader pagina={pagina && pagina}/>
        </Grid>
        <Grid xs={12}>
          <PaginaDescricao pagina={pagina && pagina.descricao} />
          <br/>
        </Grid>
        <Grid xs={12}>
          {pagina && pagina.fluxos && pagina.fluxos.map((f, i) => (
            <BpmnRoot key={i} fluxo={f} />
          ))}
        </Grid>
        <Grid xs={12}>
          <Glosario/>
        </Grid>
        <Grid xs={12} >
          <PaginaMaterialApoio/>
        </Grid>
        <Grid xs={12}>
          <PaginaMaterialApoioContent fluxos={pagina && pagina.fluxos}/>
        </Grid>
        <Grid xs={12}>
          <PaginaDescricao pagina={pagina && pagina.rodape} />
        </Grid>
      </Grid>
      <div id='rederizarDialog'/>
    </div>
  );
}